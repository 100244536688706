.home-faq {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    position: relative;
}
.faq-wrapper h6 {
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 0.2rem;
    margin-top: 0;
}
.faq-wrapper h1{
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 0.5rem;
}
#accordian-item {
    border-top: 2px solid rgba(255,255,255,0.7);
    border-bottom: 1px solid white;
    padding: 0.2rem 0;
    text-align: left;
    width: 70%;
    margin: 0 auto;
    font-family: 'Avenir-Light';
}
#accordion-title{
    display: flex;
    justify-content: space-between;
    align-items: center;
}
#accordion-title h3 {
    font-family: 'SF-Compact-Medium';
    margin-top: 0;
}
@media screen and (max-width: 1000px) {
    .faq-wrapper h1 {
        font-size: 0.1rem;
    }
    .faq-wrapper h6 {
        font-size: 0.1rem;
    }
    #accordion-title h3{
        font-size: 0.1rem;
    }
    #accordian-item {
        padding: unset
    }
    .accordion-content {
        font-size: 0.1rem
    }
}
