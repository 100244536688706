.mc-slides {
    height: 100%;
}
.mc-slides .mc-slides-hidden-up {
    -webkit-transition: top .7s,opacity .7s,visibility .7s;
    transition: top .7s,opacity .7s,visibility .7s;
}

.gs-slides .gs-slides-show {
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: top .7s,opacity .7s,visibility .7s;
    transition: top .7s,opacity .7s,visibility .7s;
}
.gs-slides .gs-slides-in {
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.home-introduction {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0 50px;
}
.home-introduction .overlay {
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}
.gs-slides .gs-slides-show .slides-animation-long, .gs-home .gs-slides .gs-slides-show .slides-animation-short {
    top: 50%;
    -webkit-transition: top 1s;
    transition: top 1s;
}
.home-introduction .introduction-info {
    position: absolute;
    color: #dfdad3;
    left: 50%;
    top: 50%;
    -webkit-transform: translate(120%,-64%);
    transform: translate(120%,-64%);
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    text-shadow: 4px 4px 13px rgb(0 0 0 / 38%);
}
.home-introduction .introduction-info.en {
    font-size: .6rem;
    text-align: right;
    -webkit-transform: translate(38%,-120%);
    transform: translate(38%,-120%);
}
.home-introduction .introduction-info .introduction-info-en-preface1 {
    font-size: .3rem;
    margin-bottom: -.05rem;
    font-family: "AvenirBlack";
}
.home-introduction .introduction-info .introduction-info-en-preface2 {
    font-size: .92rem;
    font-family: "Afolkalips";
    color: #ac4738;
}
.home-introduction .introduction-info .introduction-info-en-preface3 {
    /* color: #a83d32; */
    font-size: .18rem;
    margin-right: -.06rem;
    margin-top: 1rem;
    font-family: "Avenir-Light";
}
.introduction-info-en-preface3 {
    padding-left: 25%
}
.home-introduction .introduction-info .introduction-info-en-preface4 {
    color: #a83d32;
    font-size: .26rem;
    letter-spacing: .068rem;
    margin-right: -.08rem;
}
/* MEDIA QUERIES */
@media only screen and (max-width: 900px) {
    .mc-slides {
        height: auto!important;
        margin-bottom: 0;
        font-family: Poppins;
    }
    .home-introduction {
        height: 6.3rem;
        text-align: center;
        background-size: cover;
    }
    .home-introduction .introduction-info.en {
        transform: translateY(0%);
        width: 80%;
        /* top: 50%; */
        font-size: .14rem;
        text-align: center;
        height: 100%;
        width: 100%;
        position: absolute;
    }
    .home-introduction .introduction-info {
        position: relative;
        left: unset;
        top: unset;
        -webkit-transform: unset;
        transform: unset;
        margin: 0 auto;
    }
    .introduction-info-en-preface1 {
        font-size: .28rem;
        letter-spacing: .0072rem;
        font-family: "AvenirBlack";
    }
    .introduction-info-en-preface2 {
        font-size: .34rem;
        letter-spacing: .0072rem;
        margin-top: 0.15rem;
        font-family: "Afolkalips";
        color: #ac4738;
    }
    .introduction-info-en-preface3 {
        font-weight: 800;
        text-align: justify;
        padding-left: unset;
        padding: 15%;
        margin-top: -0.25rem;
        font-family: "Avenir-Light";
    }
    .introduction-info-en-preface4 {
        color: #a83d32;
        font-size: .18rem;
        letter-spacing: .0072rem;
        margin-top: -.1rem;
    }
.introduction-info-en-preface5 {
        color: #a83d32;
        font-size: .18rem;
        letter-spacing: .0072rem;
        margin-top: -.1rem;
    }
}