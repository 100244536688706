.home-2 {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    position: relative;
}
.home-2 .home-2-img {
    left: 0;
    /* width: 30.2rem;
    height: 100%; */
    width: 11.12rem;
    height: 10.8rem;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.overlay {
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    width: 100vw;
    left: 0;
    height: 100vh;
}
.gs-slides .gs-slides-show .slides-animation-short {
    top: 50%;
    -webkit-transition: top 1s;
    transition: top 1s;
}
.gs-slides .gs-slides-show {
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: top .7s,opacity .7s,visibility .7s;
    transition: top .7s,opacity .7s,visibility .7s;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.home-2 .home-2-info.en {
    font-size: .22rem;
    text-align: right;
    left: unset;
    /* right: 1.5rem; */
    color: #b5b4b2;
}
.gs-slides .gs-slides-show .slides-animation-long, .gs-home .gs-slides .gs-slides-show .slides-animation-short {
    top: 50%;
    -webkit-transition: top 1s;
    transition: top 1s;
}
.home-2 .home-2-info {
    left: 3rem;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.home-text-line-height div {
    padding-bottom: .009rem;
}
.introduction-info-en-title {
    color: #dfdad3;
    font-size: .32rem;
    font-family: "Cochin";
}
.home-2 .home-2-info.en {
    font-size: .19rem;
    text-align: right;
    left: unset;
    right: 1rem;
    /* color: #b5b4b2; */
    font-family: "Avenir-Light";
}
.text-red-title {
    font-size: .4rem;
    text-transform: uppercase;
    color: #a83d32;
    font-family: "Cochin";
}
.text-red-exclusive {
    font-size: .22rem;
    color: #a83d32;
}
.text-leading {
    margin-bottom: .2rem;
    font-size: 0.18rem;
    font-family: 'Avenir-Light';
}
.home-2 .home-2-img,
.home-2 .home-2-info {
    position: absolute;
    top: 56%;
    -webkit-transform: translateY(-46%);
    transform: translateY(-46%);
}
.text-title-spacing {
    margin-bottom: .45rem;
}
@media only screen and (max-width: 900px) {
    /* .home-2 {
        height: 80vh;
        background-position: top;
        font-family: Poppins;
    } */
    .home-2 .home-2-info.en {
        top: 4.5rem;
        font-size: .18rem;
        color: #7f7d7c;
        text-align: center;
        right: unset;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        white-space: pre-wrap;
        width: 85%;
        padding: 8%;
        height: unset;
    }
.introduction-info-en-preface3 {
        font-weight: 800;
        text-align: justify;
        padding-left: unset;
        padding: unset;
        margin-top: 0.75rem;
        font-family: "Avenir-Light";
        color: white;
    }
    .introduction-info-en-title {
        font-size: .28rem;
    letter-spacing: .0072rem;
    padding: 2% 15%;
    font-family: "AvenirBlack";
    color: 'white'
    }
    .home-2{
        background-size: cover;
        padding: 15%;
    text-align: center;
    /* height: 90vh; */
    }
    .text-title-spacing {
        margin-bottom: .3rem;
    }
    .text-leading {
        margin-bottom: .1rem;
    }
    .text-exclusive {
        font-size: .12rem;
        letter-spacing: .0072rem;
        text-indent: .0072rem;
    }
    .text-red {
        font-size: .12rem;
        letter-spacing: .0072rem;
    }
    .text-red-title {
        font-size: 0.25rem;
    }

}