.home-3 {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    position: relative;
}
.home-3 .home-3-img, .gs-home .home-3 .home-3-info {
    position: absolute;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
}
.home-3 .home-3-img {
    right: 0;
    width: 10.57rem;
    height: 14.8rem;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
}
.overlay {
    background-color: rgba(0,0,0,0.4);
    position: absolute;
    top: 0;
    left: 0;
    height: 100vh;
    width: 100vw;
}
.home-3 .home-3-info {
    left: 1.5rem;
    width: 100%;
    white-space: nowrap;
    text-align: left;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.home-3 .home-3-info.en {
    font-size: .22rem;
    color: #b5b4b2;
}
.home-3 .home-3-img,
.home-3 .home-3-info {
    position: absolute;
    top: 51%;
    -webkit-transform: translateY(-37%);
    transform: translateY(-37%);
}
@media only screen and (max-width: 900px) {
    .home-3 {
        /* height: 11rem; */
        background-position: top;
        font-family: Poppins;
    }
    .home-3 .home-3-info.en {
        font-weight: 800;
        text-align: justify;
        padding-left: unset;
        font-size: 0.12rem;
        margin-top: -0.25rem;
        font-family: "Avenir-Light";
        color: white;
        left: unset;
    }
    .introduction-info-en-preface3 {
        font-weight: 800;
        text-align: justify;
        padding-left: unset;
        padding: unset;
        margin-top: 0.75rem;
        font-family: "Avenir-Light";
        color: white;
    }
    .home-3 {
        background-size: cover;
    }
    .text-title-spacing {
        margin-bottom: .3rem;
    }
    .introduction-info-en-title {
        font-size: .14rem;
        letter-spacing: .0072rem;
    }
    .text-red-title {
        display: inline-block;
        font-size: .22rem;
        letter-spacing: .0072rem;
        text-indent: .0072rem;
    }   
    .text-red {
        font-size: .12rem;
        letter-spacing: .0072rem;
    }
    .text-red-exclusive {
        font-size: .12rem;
        letter-spacing: .0072rem;
        text-indent: .0072rem;
    }
    .home-3 .home-3-info {
        top: 6.9rem;
        /* -webkit-transform: translate(-50%,-50%); */
        /* transform: translate(-50%,-50%); */
    }
    .introduction-info-en-end {
        -webkit-transform: translateX(-50%);
        transform: translateX(-50%);
        display: inline-block;
        font-size: .18rem;
        letter-spacing: .0072rem;
    }
}