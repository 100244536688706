.contact-wrapper {
    display: flex;
    justify-content: space-around;
    align-items: center;
    height: 100vh;
}
.contact-left {
    text-align: left;
}
.contact-left h2 {
    font-family: 'Avenir';
    margin-bottom: 10px;
    line-height: 20px;
}
.contact-left h4 {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
    margin-bottom: 10px;
    line-height: 20px;
}
.contact-left a {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
    text-decoration: none;
    color: white;
}
.contact-details {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}
.contact-right p {
  font-family: 'Raleway', sans-serif;
  font-weight: 400;
    text-align: left;
}
.contact-right input,
.contact-right textarea {
    border: 1px solid white;
    background: transparent;
    width: 100%;
    padding: 20px 10px;
    border-radius: 10px;
    /* opacity: 70%; */
    margin: 5px 0;
    color: white;
}
.contact-right input,
.contact-right textarea:focus {
  outline: none;
}
.contact-two {
    display: flex;
    gap: 0 10px;
    width: 106%;
    justify-content: space-evenly;
}
.contact-sub {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 107%;
}
.contact-radio input {
    border: unset;
    background: unset;
    width: unset;
    padding: unset;
    border-radius: unset;
    opacity: unset;
    margin: 0 10px;
    position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
}
.contact-radio {
    text-align: left;
}
.contact-sub button {
    background-color: #51707e;
    padding: 10px 30px;
    border: none;
    border-radius: 5px;
    /* color: white; */
    font-family: 'Raleway', sans-serif;
    font-weight: 400;
    margin-left: 10px;
}
.checkmark {
    position: relative;
    top: 0;
    left: 0;
    height: 15px;
    width: 15px;
    background-color: #eee;
  }
  
  /* On mouse-over, add a grey background color */
  .container-custom:hover input ~ .checkmark {
    background-color: #ccc;
  }
  
  /* When the checkbox is checked, add a blue background */
  .container-custom input:checked ~ .checkmark {
    background-color: transparent;
  }
  
  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: relative;
    display: none;
  }
  
  /* Show the checkmark when checked */
  .container-custom input:checked ~ .checkmark:after {
    display: block;
  }
  .container-custom {
    display: inline-flex;
    justify-content: space-around;
    align-items: center;
    border-radius: 80px;
    gap: 8px;
    margin-left: 5px;
    font-family: 'Raleway', sans-serif;
    font-weight: 500;
    font-size: 0.1rem
  }
  .checkmark {
    width: 10px;
    height: 10px;
    background-color: transparent;
    border: 0.5px solid white;
  }
  /* Style the checkmark/indicator */
  .container-custom .checkmark:after {
    left: 3px;
    top: 1.5px;
    width: 1px;
    height: 3px;
    border: solid white;
    border-width: 0 3px 3px 0;
    transform: rotate(
45deg);
  }
  @media (max-width: 1000px) {
    .contact-wrapper {
        display: flex;
        flex-direction: column;
        margin: 0.5rem;
  }
  .faq-wrapper {
    padding-top: 15%;
  }
  .contact-left h1 {
    font-size: 0.2rem;
    text-align: center;
  }
  .contact-left p {
    font-size: 0.12rem;
    text-align: center;
  }
  .contact-left h3 {
    font-size: 0.18rem;
    text-align: center;
  }
}