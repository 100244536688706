.home-7 {
    height: 100vh;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    position: relative;
}
.home-7-img {
    left: 28%;
    /* width: 30.7rem;
    height: 100%; */
    width: 12.78rem;
    height: 10.8rem;
    background-repeat: no-repeat;
    background-size: 100%;
    background-position: 50%;
    position: fixed;
    top: 53%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    z-index: 0;
}
.gs-slides .gs-slides-show .slides-animation-short {
    top: 50%;
    -webkit-transition: top 1s;
    transition: top 1s;
}
.gs-slides .gs-slides-show {
    top: 0;
    opacity: 1;
    visibility: visible;
    -webkit-transition: top .7s,opacity .7s,visibility .7s;
    transition: top .7s,opacity .7s,visibility .7s;
    position: fixed;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
}
.home-7 .home-7-info.en {
    font-size: .22rem;
    text-align: right;
    left: unset;
    right: 3rem;
    color: #b5b4b2;
}
.gs-slides .gs-slides-show .slides-animation-long, .gs-home .gs-slides .gs-slides-show .slides-animation-short {
    top: 50%;
    -webkit-transition: top 1s;
    transition: top 1s;
}
.home-7 .home-7-info {
    left: 3rem;
    width: 100%;
    white-space: nowrap;
    text-align: center;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
}
.home-text-line-height div {
    padding-bottom: .009rem;
}
.introduction-info-en-title {
    color: #dfdad3;
    font-size: .32rem;
}
home-7 .home-7-info.en {
    font-size: .22rem;
    text-align: right;
    left: unset;
    right: 3rem;
    color: #b5b4b2;
}
.text-red-title {
    font-size: .4rem;
    text-transform: uppercase;
    color: #a83d32;
}
.text-red-exclusive {
    font-size: .22rem;
    color: #a83d32;
}
.text-leading {
    margin-bottom: .2rem;
}
.home-7 .home-7-img,
.home-7 .home-7-info {
    position: relative;

    -webkit-transform: translateY(-43%);
    transform: translateY(-43%);
}
.text-title-spacing {
    margin-bottom: .45rem;
}
.home-7-video-container {
    width: 100%;
    margin: 0 auto;
    padding-right: 0.8rem;
}
.button-play {
    border-style: solid;
    box-sizing: border-box;
    width: 36px;
    height: 36px;
    border-width: 18px 0px 18px 34px;
    background-color: transparent;
    border-color: transparent transparent transparent #fff;
  }
.home-7-video-wrapper {
    display: flex;
    flex-wrap: wrap;
    gap: 0.0001rem;
}
.home-7-video-inner {
    width: 70%;
}
.home-7-video {
    width: 48%;
    z-index: 1;
}
.home-7-video h1 {
    font-family: "Afolkalips";
    font-size: 1rem;
    margin-bottom: 0.4rem;
}
.video-grid {
        max-width: 100vw;
        margin-left: 15%;
        z-index: 2;
}
.caro-img {
    max-width: 100%;
    height: 100vh;
    object-fit: scale-down;
    /* aspect-ratio: 16/9; */
}
.caro-img-dis {
    max-width: 85%;
    height: 44%;
    aspect-ratio: 16/9;
    margin: auto;
    background: black;
}
#player {
    width: 125% !important

}
iframe {
    max-width: 100%;
    height: auto;
    aspect-ratio: 16/9;
}
.home-7-video-img {
    width: 85%;
    margin: 0 auto;
}
#caro-mob {
    width: 90%;
    margin: 0 auto;
}
.video-grid-item {
    padding-top: calc(100/ (16/9));
    /* height: calc(100/ (16/9)) */
    border: 1px solid #e6e6e6;
    position: relative;
}
#gameplayCar {
    /* width: 125%; */
}
#caro-pad {
    padding: 1rem
}
@media only screen and (max-width: 1000px) {
    .video-grid {
        margin-left: 0;
    }
    .home-10 {
        background-position: top;
        font-family: Poppins;
        background-size: cover;
text-align: center;
    }
    .btn-faq{
        position: absolute !important;
    z-index: 2;
    /* top: calc(50% - -30px) !important; */
    font-size: 0.4rem !important;
    left: 20px !important;
    }
    .btn-faq-right {
        position: absolute !important;
    z-index: 2;
    /* top: calc(50% - -30px) !important; */
    font-size: 0.4rem !important;
    right: 20px !important;
    }
    .home-7-video-wrapper {
        display: flex;
        flex-direction: column;
        flex-wrap: unset;
        gap: 0.0001rem;
        /* height: 100vh; */
    }
    .home-7-video {
        width: 98%;
        z-index: 1;
    }
    .home-7-video h1 {
        font-family: "Afolkalips";
        font-size: 0.4rem;
        margin-bottom: 0.4rem;
    }
    .home-7 .home-7-info.en {
        top: 4.5rem;
        font-size: .12rem;
        color: #7f7d7c;
        text-align: center;
        right: unset;
        -webkit-transform: translateY(0);
        transform: translateY(0);
        white-space: pre-wrap;
        width: 85%;
        padding: 8%;
    }
    .text-title-spacing {
        margin-bottom: .3rem;
    }
    .text-leading {
        margin-bottom: .1rem;
    }
    .text-exclusive {
        font-size: .12rem;
        letter-spacing: .0072rem;
        text-indent: .0072rem;
    }
    .text-red {
        font-size: .12rem;
        letter-spacing: .0072rem;
    }
    .caro-img {
        height: unset
    }

}