
.App {
  text-align: center;
  -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: .16rem;
    /* height: 100%; */
    overflow-x: hidden;
}

.App-logo {
  /* height: 40vmin; */
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.gs-topbar[data-v-c038550a] {
  /* font-family: KaiTi,BlinkMacSystemFont,Helvetica Neue,PingFang SC,Microsoft YaHei,Source Han Sans SC,Noto Sans CJK SC,WenQuanYi Micro Hei,Arial,sans-serif; */
  position: fixed;
  top: 0;
  right: var(--main-margin);
  height: 100vh;
  z-index: 30;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.gs-topbar .gs-topbar-logo[data-v-c038550a] {
  position: absolute;
  top: .7rem;
  right: 0;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.gs-topbar .gs-topbar-logo .gamesci-logo[data-v-c038550a] {
  width: 1.08rem;
  cursor: pointer;
}
.gs-topbar .gs-topbar-logo .b1-logo[data-v-c038550a] {
  width: .34rem;
  cursor: pointer;
  padding-top: .41rem;
  padding-right: .1rem;
}
.gs-topbar img[data-v-c038550a] {
  width: 100%;
}
.gs-topbar .gs-topbar-follow[data-v-c038550a] {
  position: absolute;
  bottom: .5rem;
  right: .3rem;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: end;
  -ms-flex-align: end;
  align-items: flex-end;
}
.gs-topbar .gs-topbar-follow .lang-ctl[data-v-c038550a] {
  margin-bottom: .3rem;
}
.gs-lang-change .lang-icon[data-v-0bf16e85] {
  width: .46875rem;
  -webkit-transform: translate(.145rem);
  transform: translate(.145rem);
  cursor: pointer;
}
.gs-topbar .gs-topbar-follow .follow-text.en[data-v-c038550a] {
  width: .5rem;
}
.gs-topbar .gs-topbar-follow .follow-text[data-v-c038550a] {
  color: #fff;
  font-size: .16rem;
  width: .39rem;
  margin-right: -.05rem;
  text-align: right;
}
.gs-topbar .gs-topbar-follow .follow-bilibili[data-v-c038550a], .gs-topbar .gs-topbar-follow .follow-sinablog[data-v-c038550a], .gs-topbar .gs-topbar-follow .follow-twitter[data-v-c038550a], .gs-topbar .gs-topbar-follow .follow-wechat[data-v-c038550a], .gs-topbar .gs-topbar-follow .follow-youtube[data-v-c038550a] {
  width: .22rem;
}
.gs-topbar .gs-topbar-follow .follow-logo[data-v-c038550a] {
  width: 100%;
}
.gs-topbar .gs-topbar-follow .follow-item[data-v-c038550a] {
  margin-top: .17rem;
  position: relative;
}
.section {
  height: fit-content;
  overflow: hidden;
}
.background {
  background-repeat: no-repeat;
    background-size: cover;
    background-position: 50%;
}
.topTitle {
  font-size: .28rem;
  letter-spacing: .0072rem;
  /* padding: 2% 15%; */
  font-family: "AvenirBlack";
  color: 'white'
}
.subtitle { 
  font-size: .34rem;
    letter-spacing: .0072rem;
    margin-top: 0.15rem;
    font-family: "Afolkalips";
    color: #ac4738;
}
@media screen and (max-width: 900px) {
  .custom-height {
    height: 140vh
  }
  
}